import React from 'react'
import './Favorites.css'
import FavoritesCom from './favoritesCom/FavoritesCom'
import favImgTest from '../../../../assets/foodBox.png'

const Favorites = () => {
    return (
        <section id='favorites_sec'>
            <div className='section_title_div'>
                <label style={{fontWeight:'bold', fontSize:'25px', width:'100%', textAlign:'left'}}>Favorites</label>
                <button className='section_button'>View All <i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div id='favorites_display'>
                <FavoritesCom amount={100} DonImg={favImgTest} title='Food Basket'/>
                <FavoritesCom amount={100} DonImg={favImgTest} title='Food Basket'/>
                <FavoritesCom amount={100} DonImg={favImgTest} title='Food Basket'/>
                <FavoritesCom amount={100} DonImg={favImgTest} title='Food Basket'/>
                <FavoritesCom amount={100} DonImg={favImgTest} title='Food Basket'/>
                <FavoritesCom amount={100} DonImg={favImgTest} title='Food Basket'/>
            </div>
        </section>
    )
}

export default Favorites
