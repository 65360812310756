import React, {useState} from 'react'
import './banner.css'
import Carousel from 'react-bootstrap/Carousel'
import donateIcon from '../../../../assets/donateIconForBanner.png'
import { useSelector } from 'react-redux'
import PopUpPayment from "../popUpPayment/PopUpPayment";


const Banner = () => {
  const [popUpModal, setPopUpModal] = useState(false);
  const [data, setData] = useState({
    compaginId:'',
    title: ''
  });
  const compaginsSlider = useSelector(state=> state.compaginsSlider);
  const handleClick = (data) => {
    setData({
      compaginId: data.compaginId,
      title: data.title
    })
    setPopUpModal(true)
  }

    return (
      <div style={{width:'100%'}}>
            <Carousel id='banner'>
              {compaginsSlider?.CAMP_DETAILS_ALL?.map(item=>
                <Carousel.Item interval={2500} key={item.CAM_ID}>
                <img
                  style={{height: '700px', width:'100%', objectFit:'cover', filter: 'brightness(90%)'}}
                  src={item.IMAGE_FILE}
                  alt={item.TITLE_E}
                />
                <Carousel.Caption>
                  <h3>{item.DESC_E}</h3>
                  <button onClick={()=>handleClick({compaginId:item.ACTV_ID, title: item.TITLE_E})} className='btn btn-light btn-block btn-donate'>Donate to {item.TITLE_E} <img src={donateIcon} alt='donate_btn_icon_banner' className='donate_btn_icon_banner'/></button>
                </Carousel.Caption>
                </Carousel.Item>
                )}
                
            </Carousel>
            <PopUpPayment
                show={popUpModal}
                data={data}
                elements={2}
                onHide={() => setPopUpModal(false)}
            />
            </div>
    )
}
export default Banner