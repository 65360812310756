import React, {useState} from 'react'
import './ProjectsPage.css'
import SL1 from '../../assets/pexels-hassan-ahmad-2259636.jpg'
import ProjectsDisplay from './sections/projectsDisplay/ProjectsDisplay'
import CountriesDisplay from './sections/countriesDisplay/CountriesDisplay'

const ProjectsPage = () => {
    const [projectSwitch, setProjectSwitch] = useState(0)
    const handleClick = () => {
        if (projectSwitch===0) {
            setProjectSwitch(1)
            document.getElementById('btn-country').style.borderBottom='2px solid #cc0000'
            document.getElementById('btn-project').style.borderBottom='none'
        } else {
            setProjectSwitch(0)
            document.getElementById('btn-project').style.borderBottom='2px solid #cc0000'
            document.getElementById('btn-country').style.borderBottom='none'
        }
    }
    return (
        <div id='projects_page'>
            <div id='donation_banner' style={{backgroundImage:`url(${SL1})`}}>
                <h1>Projects</h1>
                <div className='search_input'>
                    <input type='text' placeholder='Search for a Project...'/>
                    <button type='button'><i class="fas fa-search"></i></button>
                </div>
            </div>
            <div id='switch_project_page'>
                <button id='btn-project' onClick={handleClick} className='btn btn-block btn-light switch_btn btn-first'>Projects</button>
                <button id='btn-country' onClick={handleClick} className='btn btn-block btn-light switch_btn'>Countries</button>
            </div>
            {projectSwitch===0?
            <ProjectsDisplay/>:
            <CountriesDisplay/>
        }
        </div>
    )
}

export default ProjectsPage
