import { FETCH_ALL_PROJECTS } from '../constants/actionTypes'
import * as api from '../api'

export const getProjects = () => async (dispatch) => {
    try {
        const { data } = await api.getProjects();
        dispatch({ type: FETCH_ALL_PROJECTS, payload: data})
    } catch (error) {
        console.log(error.message);
    }
}