import axios from 'axios';


export const getSukuk = () => axios.get('https://mobapp.rcuae.ae/MOBAPP_V3/ERC_MOB_V2_WS.asmx/skuk_all?LANG=en');

//---------------------------------------------------------------------

export const getProjects = () => axios.get('https://mobapp.rcuae.ae/MOBAPP_V3/ERC_MOB_V2_WS.asmx/PRJ_ACTIVE');

//---------------------------------------------------------------------

export const getOrphanSliders = () => axios.get('https://mobapp.rcuae.ae/MOBAPP_V3/ERC_MOB_V2_WS.asmx/orphan_slider?P_LANG=11',);

//---------------------------------------------------------------------

export const getDonations = () => axios.get('https://mobapp.rcuae.ae/MOBAPP_V3/ERC_MOB_V2_WS.asmx/DONTYPE');

//---------------------------------------------------------------------

export const getCompaginsSlider = () => axios.get('https://mobapp.rcuae.ae/MOBAPP_V3/ERC_MOB_V2_WS.asmx/CAMP_DETAILS_ALL');

//---------------------------------------------------------------------

export const getHumanCases = () => axios.get('https://emiratesrc.ae/social_sup/Social_sup.asmx/SMS_HUMAN_SESSION_MOBAPP?language_id=2');

//---------------------------------------------------------------------