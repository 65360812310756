import React, {useState} from "react";
import './sukuk.css'
import SukukCom from "./sukukCom/sukukCom";
import Carousel from 'react-bootstrap/Carousel'
import { useSelector } from 'react-redux'

const Sukuk = () => {
    const sukuk = useSelector(state=> state.sukuk);

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
      };
    return (
        <section id='sukuk_sec'>
            <label style={{fontWeight:'bold', fontSize:'25px', width:'100%', textAlign:'left'}}>Sukuk/Vouchers</label>
            <div className='sukuk_display'>
                {sukuk.SKUK_NEW?.map(item=>
                    <SukukCom key={item.DONTYPE_ID} title={item.DONTYPE_NAME} sukukImg={item.URL} amount={item.amnt}/>
                    )}
            </div>
            <Carousel id='sukuk_slider' interval={null} indicators={false} controls={true} activeIndex={index} onSelect={handleSelect} style={{width: '100%'}}> 
                {sukuk.SKUK_NEW?.map(item=>
                    <Carousel.Item key={item.DONTYPE_ID}>
                        <SukukCom  title={item.DONTYPE_NAME} sukukImg={item.URL} amount={item.amnt}/>
                    </Carousel.Item>                     
                    )}
            </Carousel>
        </section>
    )
}
export default Sukuk