import React from 'react'
import './DonationPage.css'
import DonationBanner from './section/donationBanner/DonationBanner'
import DonationsDisplay from './section/donationDisplay/DonationsDisplay'

const DonationPage = () => {
    return (
        <div id='donation_page'>
            <DonationBanner/>
            <DonationsDisplay/>
        </div>
    )
}
export default DonationPage