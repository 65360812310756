import React, {useState, useEffect} from 'react'
import './humanitarianCom.css'

const HumanitarianCom = (props) => {
    const [presentage, setPresentage] = useState(0)
    const [IMG, setIMG] = useState(props.humanImg)
    useEffect(()=> {
        setPresentage((props.raised/props.goal))
        setIMG(props.humanImg)

    }, [props])
    return (
        <div className='humanitarian_com'>
            <div className='container_human' style={{padding:'30px',height:'100%',width:'100%', display:'flex', alignItems:'flex-start',justifyContent:'space-between'}}>
            <svg width="100%" height="310" className='circle_svg'>
                <defs>
                    <pattern viewBox="0 0 1 1" preserveAspectRatio="xMidYMid slice" id={`img_${props.ID}`} x="0" y="0" patternUnits="objectBoundingBox" height="100%" width="100%">
                        <image width='1' height='1' preserveAspectRatio="xMidYMid slice" className='img' x="0" y="0" xlinkHref={IMG}></image>
                    </pattern>
                </defs>
                <circle 
                  cx="50%" 
                  cy="135" r="130" 
                  fill={`url(#img_${props.ID})`}
                />
                <circle
                  class="circle-bg"
                  cx="50%"
                  cy="135"
                  r="130"
                /> 
                <circle
                  class="circle"
                  cx="50%"
                  cy="135"
                  r="130"
                  strokeDasharray= {`calc(${presentage} * ${3.14 * 2 * 115}) ${3.14 * 2 * 115}`}
                  /> 
                </svg>

                {/*--------------------------for mobile view*/}
                <svg width="100%" height="310" className='mob'>
                <defs>
                    <pattern viewBox="0 0 1 1" preserveAspectRatio="xMidYMid slice" id={`img_mob_${props.ID}`} x="0" y="0" patternUnits="objectBoundingBox" height="100%" width="100%">
                        <image width='1' height='1' preserveAspectRatio="xMidYMid slice" className='img' x="0" y="0" xlinkHref={IMG}></image>
                    </pattern>
                </defs>
                <circle 
                  cx="50%" 
                  cy="87" r="80" 
                  fill={`url(#img_mob_${props.ID})`}
                />
                <circle
                  class="circle-bg2"
                  cx="50%"
                  cy="87"
                  r="80"
                /> 
                <circle
                  class="circle2"
                  cx="50%"
                  cy="87"
                  r="80"
                  strokeDasharray= {`calc(${presentage} * ${3.14 * 2 * 87}) ${3.14 * 2 * 87}`}
                  /> 
                </svg>
                    {/*--------------------------for mobile view*/}

                <div className='details_human'>
                    <div className='human_desc'>
                        <label style={{fontWeight:'bold', width:'100%', textAlign:'left', fontSize:'21px'}}>{props.title}</label>
                        <p style={{width:'100%', textAlign:'left', fontSize:'17px'}}>{props.text.slice(0,155)}...</p>
                        <label style={{width:'100%',fontSize:'12px', textAlign:'right'}}>Read More <i class="fas fa-chevron-right"></i></label>
                    </div>
                    <svg height='310' width='100%' className='svg_board'>
                      <text text-anchor="end" x={`calc(${presentage}*90%)`} y="171" fill="black">Raised</text>
                      <text text-anchor="end" x={`calc(${presentage}*90%)`} y="188" fill="black">AED {props.raised}</text>
                      <text className='arrow' x={`calc(${presentage}*90%)`} y="205" fill="#ee4236">&#xf0dd;</text>
                      <line x1='10' y1='220' x2='90%' y2='220' stroke='grey' strokeLinecap='round' strokeWidth='15'/>
                      <line x1='10' y1='220' x2={`calc(${presentage}*90%)`} y2='220' stroke='#ee4236' strokeLinecap='round' strokeWidth='15'/>
                      <text x='90%' text-anchor="end" y="259" fill="black">Goal</text>
                      <text x='90%' text-anchor="end" y="279" fill="black">AED {props.goal}</text>
                    </svg>

                    {/*--------------------------for mobile view*/}
                    <svg height='310' width='100%' className='svg_board_mob'>
                      <text text-anchor="end" x={`calc(${presentage}*100%)`} y="171" fill="black">Raised</text>
                      <text text-anchor="end" x={`calc(${presentage}*100%)`} y="188" fill="black">AED {props.raised}</text>
                      <text className='arrow' x={`calc(${presentage}*100%)`} y="205" fill="#ee4236">&#xf0dd;</text>
                      <line x1='10' y1='220' x2='97%' y2='220' stroke='grey' strokeLinecap='round' strokeWidth='15'/>
                      <line x1='10' y1='220' x2={`calc(${presentage}*97%)`} y2='220' stroke='#ee4236' strokeLinecap='round' strokeWidth='15'/>
                      <text x='97%' text-anchor="end" y="259" fill="black">Goal</text>
                      <text x='97%' text-anchor="end" y="279" fill="black">AED {props.goal}</text>
                    </svg>
                    {/*--------------------------for mobile view*/}
                    
                </div>
                </div>
                <div className='btn_human' style={{width:'100%', backgroundColor:'white', display:'flex', alignItems:'center', justifyContent:'center'}}>
                <button className='btn btn-block btn-light btn-donate shadow_here'>Donate Now <i class="fas fa-long-arrow-alt-right"></i></button>
                </div>
        </div>
    )
}
export default HumanitarianCom

