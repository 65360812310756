import React, { useEffect } from 'react';
import './App.css';
import Header from './components/LoaclComponents/header/header';
import HomePage from './components/homePage/homepage';
import Footer from './components/LoaclComponents/footer/footer';
import LoginSign from './components/loginSignPage/loginSign';
import DonationPage from './components/donationPage/DonationPage';
import HumanCase from './components/humanCasePage/HumanCase';
import ProjectsPage from './components/projectsPage/ProjectsPage';
import SponsorPage from './components/sponsorPage/SponsorPage';
import UserSponsor from './components/userSponsorsPage/UserSponsor';
import OrphanReport from './components/orphanReport/OrphanReport';
import AllSukukPage from './components/allSukukPage/AllSukukPage';
import DonationSupportPage from './components/donationSupportPage/DonationSupportPage';
import MyBag from './components/bagPage/MyBag';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { getOrphanSliders } from './actions/orphanSliders';
import { getProjects } from './actions/projects';
import { getCompaginSliders } from './actions/compaginsSliders';
import { getDonations } from './actions/donations';
import { getHumanCases } from './actions/humanCases';
import {getSukuk } from './actions/sukuk'

function App() {
  const dispatch = useDispatch();
  useEffect(()=> {
    dispatch(getOrphanSliders());
    dispatch(getProjects());

    dispatch(getCompaginSliders());
    dispatch(getDonations());
    dispatch(getHumanCases());
    dispatch(getSukuk());

  }, [dispatch]);
  return (
    <div className="App">
      <Header/>
      <Switch>
        <Route path="/" component={HomePage} exact />
        <Route path="/login" component={LoginSign} />
        <Route path="/donations" component={DonationPage} />
        <Route path="/humancases/:id" component={HumanCase} />
        <Route path="/projects" component={ProjectsPage} />
        <Route path="/sponsororphan" component={SponsorPage} />
        <Route path="/mysponsors" component={UserSponsor} />
        <Route path="/orphanreport/:id" component={OrphanReport} />
        <Route path="/sukuk" component={AllSukukPage} />
        <Route path="/supportkid/:id" component={DonationSupportPage} />
        <Route path="/mybag" component={MyBag} />
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;
