import { FETCH_ALL_COMPAGINS_SLIDERS } from '../constants/actionTypes'
import * as api from '../api'

export const getCompaginSliders = () => async (dispatch) => {
    try {
        const { data } = await api.getCompaginsSlider();
        dispatch({ type: FETCH_ALL_COMPAGINS_SLIDERS, payload: data})
    } catch (error) {
        console.log(error.message);
    }
}