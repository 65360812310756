import React from 'react'
import './projectsCom.css'

const ProjectsCom = (props) => {
    return (
        <div className='projects_com'>
            <img src={props.img} alt={props.project_img_alt}className='project_img'/>
            <label style={{width:'100%', textAlign:'center'}}>{props.title} <span style={{textAlign:'left', color:'black'}}><i class="fas fa-long-arrow-alt-right"></i></span></label>
        </div>
    )
}
export default ProjectsCom