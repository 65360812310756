import React from 'react'
import './becomeASpon.css'
import BecomeASponCom from './becomeASponCom/becomeASponCom'
import { useSelector } from 'react-redux'

const BecomeASpon = (props) => {
    const orphanSliders = useSelector(state=> state.orphanSliders);
    return (
        <section id='becomeASpon_sec'>
            <label style={{fontWeight:'bold', fontSize:'25px', width:'100%', textAlign:'left'}}>Become a Sponsor</label>
            <div className='becomeASpon'>
                {orphanSliders?.items?.map(item => 
                    <BecomeASponCom key={item.orptype_id} alt_text={item.orptype_txt} image={item.orptype_url} text={item.orptype_name}/>
                )}
            </div>
        </section>
    )
}
export default BecomeASpon