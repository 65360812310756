import React from 'react'
import './DonationsDisplay.css'
import DonationCom from '../../../homePage/sections/donations/donationCom/donationCom'
import { useSelector } from 'react-redux'


const DonationsDisplay = () => {
    const donations = useSelector(state=> state.donations);
    return (
        <div id='donation_page_display'>
            <label style={{fontWeight:'bold', fontSize:'25px', width:'100%', textAlign:'left', textDecoration:'underline', textDecorationColor:'#ee4236' }}>Donations</label>
            <div className='donation_page_display'>
            {donations?.DONTYPE?.map(item=> 
                    <DonationCom altImg={item.DONTYPE_NAME_E} amount={item.COUPON_AMNT} key={item.DONTYPE_ID} DonImg={`https://www.emiratesrc.ae/mobileDonate/coup/banner/${item.DONTYPE_ID}.jpg`} title={item.DONTYPE_NAME_E}/>
                    )}
            </div>
        </div>
    )
}

export default DonationsDisplay
