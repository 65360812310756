import React from 'react'
import BagItemCom from './bagItemCom/BagItemCom'
import './MyBag.css'
import ERCSmallLogo from '../../assets/ERC.svg'
import barCode from '../../assets/barcodeEx.png'
import DonationCom from '../homePage/sections/donations/donationCom/donationCom'
import foodBasket from '../../assets/foodbasket.png'

const MyBag = () => {
    return (
        <div id='my_bag'>
            <div id='my_bag_left_side'>
                <h5 style={{fontWeight:'bold'}}>My Bag</h5>
                <div className='items_on_bag_display'>
                    <BagItemCom title='Food Basket' amount={20} itemImg={foodBasket}/>
                    <BagItemCom title='Food Basket' amount={50} itemImg={foodBasket}/>
                    <BagItemCom title='Food Basket' amount={20} itemImg={foodBasket}/>
                    <BagItemCom title='Food Basket' amount={250} itemImg={foodBasket}/>
                </div>
            </div>
            <div className='my_bag_right_side'>
                <h5 style={{fontWeight:'bold'}}>Donation Summary</h5>
                <div className='up_right_side'>
                    <div className='my_bag_table'>
                        <div className='btn_title_1'>
                            <label>Reciept</label>
                            <img className='ERC_receipt_logo' src={ERCSmallLogo} alt='ERC_reciept_logo'/>
                        </div>
                        <div className='btn_title_2'>
                            <label>Type</label>
                            <label>Amount</label>
                        </div>
                        <hr/>
                        <div className='up_right_side_items_display'>
                            <div className='up_right_side_items_display_right'>
                                <label>Winter Clothes</label>
                                <label>Student Desks</label>
                                <label>Smart Devices</label>
                                <label>Medical Care</label>
                            </div>
                            <div className='up_right_side_items_display_left'>
                                <label>AED 20</label>
                                <label>AED 50</label>
                                <label>AED 20</label>
                                <label>AED 250</label>
                            </div>
                        </div>
                        <hr/>
                        <div className='total_circular_reciept'>
                            <div className='my_bag_receipt_circle'/>
                            <div className='total_dashcode'>
                                <div className='total_dashcode_table'>
                                    <label>Total</label>
                                    <label>AED 340</label>
                                </div>
                                <hr/>
                                <img className='ERC_receipt_logo' src={barCode} alt='ERC_reciept_logo'/>
                            </div>
                            <div className='my_bag_receipt_circle'/>
                        </div>
                    </div>
                </div>
                <h5 style={{fontWeight:'bold'}}>Payment Method</h5>
                <div className='down_right_side'>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked/>
                        <label class="form-check-label" for="exampleRadios1">
                        xxxx-1548
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2"/>
                        <label class="form-check-label" for="exampleRadios2">
                        xxxx-4558
                        </label>
                    </div>
                    <div class="form-check disabled">
                        <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3" disabled/>
                        <label class="form-check-label" for="exampleRadios3">
                        Apple Pay
                        </label>
                    </div>
                    <button className='btn btn-block btn-danger btn_submit_myBag'>Add New Card</button>
                </div>
                <button className='btn btn-block btn-danger btn_submit_myBag'>Donate Now</button>
            </div>
            <div className='my_bag_bottom'>
                <h5 style={{fontWeight:'bold'}}>You May Also Donate For...</h5>
                <div className='donation_display_my_bag'>
                    <DonationCom DonImg={foodBasket} title='Food Basket'/>
                    <DonationCom DonImg={foodBasket} title='Food Basket'/>
                    <DonationCom DonImg={foodBasket} title='Food Basket'/>
                    <DonationCom DonImg={foodBasket} title='Food Basket'/>
                </div>
            </div>
        </div>
    )
}

export default MyBag