import React from 'react'
import './UserSponsorCom.css'

const UserSponsorCom = (props) => {
    return (
        <div className='user_sponsor_com'>
            <div className='user_sponsor_com_above_content'>
                <div className='above_content'>
                    <img src={props.img} alt='user_sponsor' className='user_sponsor_img'/>
                    <div className='user_sponsor_details'>
                        <label style={{fontWeight:'bold'}}>{props.name}</label>
                        <div className='detals_table'>
                            <label style={{color:'#0099ff'}}>Nationality     : <span style={{color:'black'}}>{props.nationality}</span></label>
                            <label style={{color:'#0099ff'}}>Beneficairy No. : <span style={{color:'black'}}>{props.beneficairy}</span></label>
                            <label style={{color:'#0099ff'}}>Date of Birth   : <span style={{color:'black'}}>{props.birth}</span></label>
                            <label style={{color:'#0099ff'}}>Sponsor Date    : <span style={{color:'black'}}>{props.Sdate}</span></label>
                        </div>
                    </div>
                </div>
                <div class="dropdown">
                  <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="/">Reports</a>
                    <a class="dropdown-item" href="/">Arrears</a>
                  </div>
                </div>
            </div>
            <button className='btn btn-block btn-danger btn_send_user_spon'>Send a Gift</button>
        </div>
    )
}

export default UserSponsorCom
