import React, {useState} from 'react'
import './sukukCom.css'

const SukukCom = (props) => {
    const [sukukAmount, setSukukAmount] = useState(parseInt(props.amount));
    const [liked, setLiked] = useState(true)

    const handleClick = (op) => {
        if (op==='-') {
            if (sukukAmount>0) {
                setSukukAmount(sukukAmount-1);
            }
        } else {
            setSukukAmount(sukukAmount+1);
        }
    }
    return (
        <div className='sukukCom'>
            <label style={{width:'100%', textAlign:'left', fontSize:'20px', fontWeight:'bold'}}>{props.title}</label>
            <div className='sukuk_item'>
                <div className='sukuk_item_img' style={{backgroundImage: `url(${props.sukukImg})`}} alt={props.title}>
                    <button onClick={()=> liked? setLiked(false): setLiked(true)} className='like_btn'>{liked? <i style={{color:'#ee4236'}} class="fas fa-heart"></i>:<i style={{color:'white'}} class="fas fa-heart"></i>}</button>
                </div>
                <div className='sukuk_item_board'>
                    <div className='sukuk_item_board_time'>
                        <button className='btn btn-block btn-light btn-sm px30_btn'>Daily</button>
                        <button className='btn btn-block btn-light btn-sm px30_btn'>Monthly</button>
                        <button className='btn btn-block btn-light btn-sm px30_btn'>Yearly</button>
                    </div>
                    <div className='sukuk_item_board_payment'>
                        <button className='btn btn-block btn-light px50_btn'><i class="fas fa-hand-holding-medical"></i></button>
                        <button className='btn btn-block btn-light px50_btn'><i class="fab fa-apple-pay"></i></button>
                        <button className='btn btn-block btn-light px50_btn'><i class="fas fa-sms"></i></button>
                    </div>
                    <div className='sukuk_inc_Dec'>
                        <button className='sukukdec btn btn-light' onClick={()=> handleClick('-')}>-</button>
                        <label style={{fontSize:'15px', fontStyle:'italic', fontWeight:'bold'}}>AED <span style={{fontSize:'20px', fontWeight:'bold', fontStyle:'none'}}>{sukukAmount}</span></label>
                        <button className='sukukinc btn btn-light' onClick={()=> handleClick('+')}>+</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SukukCom