import React, {useState} from 'react'
import './FavoritesCom.css'
import donateIcon from '../../../../../assets/donateicon.png'

const FavoritesCom = (props) => {
    const [donationAmount, setDonationAmount] = useState(props.amount);
    const [liked, setLiked] = useState(true)
    const handleClick = (op) => {
        if (op==='-') {
            if (donationAmount>0) {
            setDonationAmount(donationAmount-1);
            }
        } else {
            setDonationAmount(donationAmount+1);
        }
    }
    return (
        <div className='favorites_com'>
            <button className='donate_btn_wrap'><img src={donateIcon} alt='icon_for_favorites' className='icon_for_favorites'/></button>
            <div className='favorites_item'>
            <div style={{backgroundImage:`url(${props.DonImg})`}} className='donation_img'>
                    <button onClick={()=> liked? setLiked(false): setLiked(true)} className='like_btn'>{liked? <i style={{color:'#ee4236'}} class="fas fa-heart"></i>:<i style={{color:'white'}} class="fas fa-heart"></i>}</button>
                </div>
                <div className='favorites_details'>
                    <label style={{width:'100%', textAlign:'left', fontSize:'17px', lineHeight:'1cm'}}>{props.title}</label>
                    <div className='favorites_inc_Dec'>
                        <button className='dec' onClick={()=> handleClick('-')}>-</button>
                        <label style={{fontSize:'15px', fontStyle:'italic', fontWeight:'bold'}}>AED <span style={{fontSize:'20px', fontWeight:'bold', fontStyle:'none'}}>{donationAmount}</span></label>
                        <button className='inc' onClick={()=> handleClick('+')}>+</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FavoritesCom
