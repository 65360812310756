import React, {useState} from 'react'
import './HumanCase.css'
import humanCase from '../../assets/pexels-cottonbro-6202735.jpg'

const HumanCase = () => {
    const [presentage, setPresentage] = useState(70000/100000)

    return (
        <div id='human_case_page'>
            <h1><span style={{textDecoration:'underline', textDecorationColor:'#cc0000'}}>Support Ahmed</span> Recovery and medical expenses</h1>
            <div className='human_case_display'>
                <div className='human_case_img'>
                    <img src={humanCase} alt='humen_case' className='human_img'/>
                    <svg  height='210' width='100%' className='svg_board'>
                      <text x={`calc(${presentage}*100%)`} y="71" fill="black">Raised</text>
                      <text x={`calc(${presentage}*100%)`} y="88" fill="black">AED 70000</text>
                      <text className='arrow' x={`calc(${presentage}*100%)`} y="105" fill="red">&#xf0dd;</text>
                      <line x1='10' y1='120' x2='95%' y2='120' stroke='grey' strokeLinecap='round' strokeWidth='15'/>
                      <line x1='10' y1='120' x2={`calc(${presentage}*100%)`} y2='120' stroke='red' strokeLinecap='round' strokeWidth='15'/>
                      <text x='92%' y="159" fill="black">Goal</text>
                      <text x='83%' y="179" fill="black">AED 100000</text>
                      <text className='arrow' x='95%' y="145" fill="grey">&#xf0de;</text>
                    </svg>
                </div>
                <div className='human_case_details'>
                    <p>On July 17,2021, Ahmed was in Angel Fire, New Mexico doing what he loves the most in the world: mountain biking-when his life changed forever. Although Ahmed is an expert rider and was on a trail he had ridden more than sixty times, he lost control of bike and remembers walking up with no feeling below his chest. He had broken his T6-T7 vertebrae, almost completely severing his spinal cord.</p>
                    <svg width="100%" height="310" className='circle_svg'>
                        <text x='40%' y='155' className='text_for_svg' fill='black'>AED 70,000</text>
                        <text x='32%' y='305' className='text_for_svg_down' fill='black'>AED 70,000 Raised of AED 100,000</text>
                        <circle 
                          cx="50%" 
                          cy="135" r="130" 
                          fill="none" 
                        />
                        <circle
                          class="circle-bg"
                          cx="50%"
                          cy="135"
                          r="130"
                        /> 
                        <circle
                          class="circle"
                          cx="50%"
                          cy="135"
                          r="130"
                          strokeDasharray= {`calc(${presentage} * ${3.14 * 2 * 115}) ${3.14 * 2 * 115}`}
                          /> 
                    </svg>
                    <div className='human_donation_amount'>
                        <h4>Donation Amount</h4>
                        <input className='donation_amount_input' placeholder='Enter amount in AED'/>
                    </div>
                    <div className='human_donation_btns'>
                        <div className='human_donation_btns_above'>
                            <button className='btn btn-block btn-light human_donate_ways btn1'><i style={{fontSize:'50px'}} class="fas fa-hand-holding-medical"></i></button>
                            <button className='btn btn-block btn-light human_donate_ways btn2'><i style={{fontSize:'50px'}} class="fas fa-sms"></i></button>
                            <button className='btn btn-block btn-light human_donate_ways btn3'><i style={{fontSize:'50px'}} class="fas fa-hand-holding-usd"></i></button>
                        </div>
                        <button className='btn btn-block btn-dark apple_human_btn'><i style={{fontSize:'40px'}} class="fab fa-apple-pay"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HumanCase
