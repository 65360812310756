export const FETCH_ALL_SUKUK = 'FETCH_ALL_SUKUK';

//---------------------------------------------------

export const FETCH_ALL_PROJECTS = 'FETCH_ALL_PROJECTS';

//---------------------------------------------------

export const FETCH_ALL_ORPHAN_SLIDERS = 'FETCH_ALL_ORPHAN_SLIDERS';

//---------------------------------------------------

export const FETCH_ALL_COMPAGINS_SLIDERS = 'FETCH_ALL_COMPAGINS_SLIDERS';

//---------------------------------------------------

export const FETCH_ALL_DONATIONS = 'FETCH_ALL_DONATIONS';

//---------------------------------------------------

export const FETCH_ALL_HUMANCASES = 'FETCH_ALL_HUMANCASES';

//---------------------------------------------------