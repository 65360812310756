import { FETCH_ALL_DONATIONS } from '../constants/actionTypes'
import * as api from '../api'

export const getDonations = () => async (dispatch) => {
    try {
        const { data } = await api.getDonations();
        dispatch({ type: FETCH_ALL_DONATIONS, payload: data})
    } catch (error) {
        console.log(error.message);
    }
}