import React from 'react'
import './DonationBanner.css'
import SL1 from '../../../../assets/donationsPage_banner.jpg'

const DonationBanner = () => {
    return (
        <div id='donation_banner' style={{backgroundImage:`url(${SL1})`}}>
            <h1>Donations</h1>
            <div className='search_input'>
                <input type='text' placeholder='Search for a donation'/>
                <button type='button'><i class="fas fa-search"></i></button>
            </div>
        </div>
    )
}

export default DonationBanner
