import React, {useState} from 'react'
import './loginSign.css'
import ERCLogo from '../../assets/logo.png'
import ERCLogomob from '../../assets/logo.svg'
import BCE1 from '../../assets/pexels-hitesh-choudhary-1739842.jpg'
import BCE2 from '../../assets/pexels-sadman-chowdhury-1440388.jpg'
import fac from '../../assets/facebook.svg'
import apple from '../../assets/apple.svg'
import goo from '../../assets/google.svg'
import {useHistory} from 'react-router-dom'

export const LoginSign = () => {
  const history = useHistory();
    const [display, setDisplay] = useState(0);
    const openFromLog = () => {
      history.push('/')
    }
    return (
        <div id='login_sign'>
            <div className='shapes_login'>
               <div id='login_sign_img5' style={{backgroundImage:`url(${BCE2})`}}>
               </div>
               <div id='login_sign_img' style={{backgroundImage:`url(${BCE1})`}}>
               <img  src={ERCLogo} alt='ERC_logo' className='ERC_logo_mob'style={{height:'80px', filter:'grayscale(90%)', opacity:'1'}}/>
               </div>
               <div id='login_sign_img3' style={{backgroundImage:`url(${BCE2})`}}>
               </div>
            </div>
            {display===0?
            <form className='login_form'>
            <img src={ERCLogo} onClick={openFromLog} alt='ERC_logo' style={{cursor:'pointer', alignSelf:'center', width:'300px', height:'75px', objectFit:'fill', filter:'grayscale(100%)'}} className='ERC_logo'/>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                 <label style={{fontWeight:'bold', fontSize:'30px'}}>Hello,</label>
                 <label>Please Login to Continue...</label>
            </div>
              <div className='whole_input'>
                 <i style={{fontSize:'30px'}} class="fas fa-user"></i>
                  <div className="input">
                    <label for="exampleInputEmail1">E-mail, Mobile, ID, File Number</label>
                    <input type="email" class="form-control" id="exampleInputEmail1"/>
                    <a href='/' id="emailHelp" class="form-text">Forgot Account?</a>
                  </div>
              </div>
              <div className='whole_input'>
                  <i style={{fontSize:'30px', color:'#cc0000'}} class="fas fa-lock"></i>
                  <div className="input">
                    <label for="exampleInputPassword1">Password</label>
                    <input type="password" class="form-control" id="exampleInputPassword1"/>
                    <a href='/' id="emailHelp" class="form-text">Forgot Account?</a>
                  </div>
              </div>
              <button type="submit" class="btn btn-block btn-danger btn-lg btn_for_form">SIGN IN</button>
              <a onClick={()=> setDisplay(1)} type="button" class="btn btn-block btn-outline-danger btn-lg btn_for_form">Creact an Account</a>
              <div className='hrs'>
                  <hr/>
                  Or Sign in with
                  <hr/>
              </div>
              <div className='social_media_log'>
                  <img src={fac} alt='facebook_logo' className='login_sign_social_logos'/>
                  <img src={goo} alt='google_logo' className='login_sign_social_logos'/>
                  <img src={apple} alt='apple_logo' className='login_sign_social_logos'/>
              </div>
            </form>:
            <form className='login_form'>
            <img src={ERCLogo} alt='ERC_logo' style={{alignSelf:'center', width:'300px', height:'75px', objectFit:'fill'}} className='ERC_logo'/>
            <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
                 <label style={{fontWeight:'bold', fontSize:'30px'}}>Hello,</label>
                 <label>Here you can create your acount...</label>
            </div>
            <div className='whole_input'>
                 <i style={{fontSize:'30px'}} class="fas fa-user"></i>
                  <div className="input">
                    <label for="exampleInputEmail1">ID</label>
                    <input type="text" class="form-control" id="exampleInputEmail1"/>
                  </div>
              </div>
              <div className='whole_input'>
                 <i style={{fontSize:'30px'}} class="fas fa-user"></i>
                  <div className="input">
                    <label for="exampleInputEmail1">E-mail</label>
                    <input type="email" class="form-control" id="exampleInputEmail1"/>
                  </div>
              </div>
              <div className='whole_input'>
                  <i style={{fontSize:'30px', color:'#cc0000'}} class="fas fa-lock"></i>
                  <div className="input">
                    <label for="exampleInputPassword1">Password</label>
                    <input type="password" class="form-control" id="exampleInputPassword1"/>
                  </div>
              </div>
              <div className='whole_input'>
                  <i style={{fontSize:'30px', color:'#cc0000'}} class="fas fa-lock"></i>
                  <div className="input">
                    <label for="exampleInputPassword2">Repeat Password</label>
                    <input type="password" class="form-control" id="exampleInputPassword2"/>
                  </div>
              </div>
              <button type="submit" class="btn btn-block btn-danger btn-lg btn_for_form">SIGN UP</button>
              <a onClick={()=> setDisplay(0)} type="button" class="btn btn-block btn-outline-danger btn-lg btn_for_form">Have an account?</a>
              <div className='hrs'>
                  <hr/>
                  Or Sign up with
                  <hr/>
              </div>
              <div className='social_media_log'>
                  <img src={fac} alt='facebook_logo' className='login_sign_social_logos'/>
                  <img src={goo} alt='google_logo' className='login_sign_social_logos'/>
                  <img src={apple} alt='apple_logo' className='login_sign_social_logos'/>
              </div>
            </form>
        }
        </div>
    )
}
export default LoginSign