import React from 'react'
import './donation.css'
import DonationCom from './donationCom/donationCom'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'



const Donation = () => {
    const donations = useSelector(state=> state.donations);
    const history = useHistory();
    const handleClick = () => {
        history.push(`/donations`)
    }

    return (
        <section id='donations_sec'>
            <div className='section_title_div'>
                <label style={{fontWeight:'bold', fontSize:'25px', width:'100%', textAlign:'left'}}>Donations</label>
                <button onClick={handleClick} className='section_button'>View All <i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div id='donation_display'>
                {donations?.DONTYPE?.slice(donations?.DONTYPE?.length-8)?.map(item=> 
                    <DonationCom altImg={item.DONTYPE_NAME_E} amount={item.COUPON_AMNT} key={item.DONTYPE_ID} DonImg={`https://www.emiratesrc.ae/mobileDonate/coup/banner/${item.DONTYPE_ID}.jpg`} title={item.DONTYPE_NAME_E}/>
                    )}
            </div>
        </section>
    )
}
export default Donation