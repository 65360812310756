import React, {useState} from 'react'
import './SponsorPage.css'
import bc from '../../assets/pexels-sadman-chowdhury-1440388.jpg'

const SponsorPage = () => {
    const [totalAmount, setTotalAmount] = useState(4800);

    const handleClick = (str) => {
        if (str==='6') {
            document.getElementById('btn1').style.order='3'
            document.getElementById('btn1').style.opacity='1'
            document.getElementById('btn1').style.fontWeight='bold'

            document.getElementById('btn2').style.fontWeight='unset'
            document.getElementById('btn3').style.fontWeight='unset'
            document.getElementById('btn4').style.fontWeight='unset'
            document.getElementById('btn5').style.fontWeight='unset'


            document.getElementById('btn5').style.order='2'
            document.getElementById('btn5').style.opacity='0.5'
            document.getElementById('btn4').style.order='1'
            document.getElementById('btn4').style.opacity='0.2'
            document.getElementById('btn3').style.order='5'
            document.getElementById('btn3').style.opacity='0.2'
            document.getElementById('btn2').style.order='4'
            document.getElementById('btn2').style.opacity='0.5'

            setTotalAmount(1200);
        } else if (str==='1') {
            document.getElementById('btn2').style.order='3'
            document.getElementById('btn2').style.opacity='1'
            document.getElementById('btn2').style.fontWeight='bold'

            document.getElementById('btn1').style.fontWeight='unset'
            document.getElementById('btn3').style.fontWeight='unset'
            document.getElementById('btn4').style.fontWeight='unset'
            document.getElementById('btn5').style.fontWeight='unset'

            document.getElementById('btn5').style.order='1'
            document.getElementById('btn5').style.opacity='0.2'
            document.getElementById('btn4').style.order='5'
            document.getElementById('btn4').style.opacity='0.2'
            document.getElementById('btn3').style.order='4'
            document.getElementById('btn3').style.opacity='0.5'
            document.getElementById('btn1').style.order='2'
            document.getElementById('btn1').style.opacity='0.5'
            setTotalAmount(2400);
        } else if (str==='2') {
            document.getElementById('btn3').style.order='3'
            document.getElementById('btn3').style.opacity='1'
            document.getElementById('btn3').style.fontWeight='bold'

            document.getElementById('btn2').style.fontWeight='unset'
            document.getElementById('btn1').style.fontWeight='unset'
            document.getElementById('btn4').style.fontWeight='unset'
            document.getElementById('btn5').style.fontWeight='unset'

            document.getElementById('btn5').style.order='5'
            document.getElementById('btn5').style.opacity='0.2'
            document.getElementById('btn4').style.order='4'
            document.getElementById('btn4').style.opacity='0.5'
            document.getElementById('btn2').style.order='2'
            document.getElementById('btn2').style.opacity='0.5'
            document.getElementById('btn1').style.order='1'
            document.getElementById('btn1').style.opacity='0.2'
            setTotalAmount(4800);
        } else if (str==='3') {
            document.getElementById('btn4').style.order='3'
            document.getElementById('btn4').style.opacity='1'
            document.getElementById('btn4').style.fontWeight='bold'

            document.getElementById('btn1').style.fontWeight='unset'
            document.getElementById('btn2').style.fontWeight='unset'
            document.getElementById('btn3').style.fontWeight='unset'
            document.getElementById('btn5').style.fontWeight='unset'

            document.getElementById('btn5').style.order='4'
            document.getElementById('btn5').style.opacity='0.5'
            document.getElementById('btn1').style.order='5'
            document.getElementById('btn1').style.opacity='0.2'
            document.getElementById('btn2').style.order='1'
            document.getElementById('btn2').style.opacity='0.2'
            document.getElementById('btn1').style.order='2'
            document.getElementById('btn1').style.opacity='0.5'
            setTotalAmount(7200);
        } else if (str==='4') {
            document.getElementById('btn5').style.order='3'
            document.getElementById('btn5').style.opacity='1'
            document.getElementById('btn5').style.fontWeight='bold'

            document.getElementById('btn1').style.fontWeight='unset'
            document.getElementById('btn2').style.fontWeight='unset'
            document.getElementById('btn3').style.fontWeight='unset'
            document.getElementById('btn4').style.fontWeight='unset'

            document.getElementById('btn3').style.order='1'
            document.getElementById('btn3').style.opacity='0.2'
            document.getElementById('btn1').style.order='4'
            document.getElementById('btn1').style.opacity='0.5'
            document.getElementById('btn2').style.order='5'
            document.getElementById('btn2').style.opacity='0.2'
            document.getElementById('btn4').style.order='2'
            document.getElementById('btn4').style.opacity='0.5'
            setTotalAmount(9600);
        }
    }
    return (
        <div id='sponsor_page'>
            <div className='sponsor_banner' style={{backgroundImage:`url(${bc})`, backgroundPosition:'center center'}}>
                <h1 style={{color:'white'}}>Sponsor an Orphan</h1>
            </div>
            <div className='sponsor_display_content'>
                <h4>Kindly Select the Payment Option</h4>
                <div className='payment_section'>
                    <div className='payment_datails'>
                        <div className='choose_amount'>
                            <h4><i style={{color:'#cc0000', alignSelf:'flex-start'}} class="fas fa-money-bill"></i> Full Payment</h4>
                            <div className='spin_salary'>
                                <button id='btn1' onClick={()=> handleClick('6')} style={{opacity: '0.2', order:'1'}} className='btn btn-block btn-light'>6 Months</button>
                                <button id='btn2' onClick={()=> handleClick('1')} style={{opacity: '0.5', order:'2'}} className='btn btn-block btn-light'>1 Year</button>
                                <button id='btn3' onClick={()=> handleClick('2')} style={{fontWeight:'bold', order:'3'}} className='btn btn-block btn-light'>2 Years</button>
                                <button id='btn4' onClick={()=> handleClick('3')} style={{opacity: '0.5', order:'4'}} className='btn btn-block btn-light'>3 Years</button>
                                <button id='btn5' onClick={()=> handleClick('4')} style={{opacity: '0.1', order:'5'}} className='btn btn-block btn-light'>4 Years</button>
                            </div>
                            <div style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between', alignSelf:'center'}}>
                            <h5>Total Payment</h5>
                                <label><span style={{fontSize:'10px', fontWeight:'bold'}}>AED</span> {totalAmount}</label>
                            </div>
                        </div>
                        <div className='display_amount'>
                            <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start', gap:'10px'}}>
                                <i class="fas fa-calendar-alt"></i>
                                <label>Monthly Payment</label>
                            </div>
                            <label><span style={{fontSize:'10px'}}>AED</span> 200</label>
                        </div>
                    </div>
                    <div className='human_donation_btns_spon'>
                        <div className='human_donation_btns_above_spon'>
                            <button className='btn btn-block btn-light human_donate_ways btn1'><i style={{fontSize:'50px'}} class="fas fa-hand-holding-medical"></i></button>
                            <button className='btn btn-block btn-light human_donate_ways btn3'><i style={{fontSize:'50px'}} class="fas fa-hand-holding-usd"></i></button>
                        </div>
                        <button style={{width:'80%'}} className='btn btn-block btn-dark apple_human_btn'><i style={{fontSize:'30px'}} class="fab fa-apple-pay"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SponsorPage