import { FETCH_ALL_SUKUK } from '../constants/actionTypes'
import * as api from '../api'

export const getSukuk = () => async (dispatch) => {
    try {
        const { data } = await api.getSukuk();
        dispatch({ type: FETCH_ALL_SUKUK, payload: data})
    } catch (error) {
        console.log(error.message);
    }
}