import React from 'react'
import './AllSukukPage.css'
import AllSukukCom from './allSukukCom/AllSukukCom'
import sukukLogo from '../../assets/sukuklogo.png'
import sukukimg from '../../assets/pexels-hitesh-choudhary-1739842.jpg'
import sukukimg2 from '../../assets/pexels-hassan-ahmad-2259636.jpg'
import sukukimg3 from '../../assets/pexels-cdc-3992949.jpg'


const AllSukukPage = () => {
    return (
        <div id='all_sukuk_page'>
            <div id='ad_for_sukuk'>
                <img src={sukukLogo} alt='sukuk_logo' className='sukuk_logo'/>
                <p style={{flex:'2'}}>
                    The Emirates Red Crescent Authority, in cooperation with Abu Dhabi Islamic Bank, in launching the first initiative of its kind, Sukuk Al-Khair are sustainable humanitarian financial instruments, The Authority invsts it and takes advantage of the investment profits to serve various humanitarian programs.
                </p>
            </div>
            <div className='all_sukuk_page_display'>
                <AllSukukCom title='Medical Suk' sukukImg={sukukimg}/>
                <AllSukukCom title='Building Mosque Suk' sukukImg={sukukimg2}/>
                <AllSukukCom title='Educational Suk' sukukImg={sukukimg3}/>
            </div>
        </div>
    )
}

export default AllSukukPage
