import React, {useState} from 'react'
import './OrphanReport.css'
import dana from '../../assets/poor2.jpg'

const OrphanReport = () => {
    const [display, setDisplay] = useState({
        btn1: 1,
        btn2: 1,
        btn3: 1,
        btn4: 0
    })
    return (
        <div id='orphan_report'>
            <h1 style={{padding:'40px', textDecoration:'underline', textDecorationColor:'#cc0000'}}>Orphan Report</h1>
            <div id='orphan_person_details'>
                <div id='orphan_person_info'>
                    <div id='orphan_person_info_left_side'>
                        <label style={{color:'#cc0000'}}>Nationality     : <span style={{color:'black'}}>Togo</span></label>
                        <label style={{color:'#cc0000'}}>Beneficairy No. : <span style={{color:'black'}}>156465231</span></label>
                    </div>
                    <div id='orphan_person_info_right_side'>
                        <label style={{color:'#cc0000'}}>Date of Birth   : <span style={{color:'black'}}>27/02/2014</span></label>
                        <label style={{color:'#cc0000'}}>Sponsor Date    : <span style={{color:'black'}}>4/03/2014</span></label>
                    </div>
                </div>
                <div className='person_img_name'>
                    <label style={{fontWeight:'bold', marginTop:'-90px'}}>Dana Ahmed Ali</label>
                    <img src={dana} alt='orphan_person_img' className='orphan_person_img'/>
                </div>
            </div>
            <div className='tables_display_section'>
                <div className='data_box'>
                    <div className='title_bar'>
                        <label>Beneficairy Message</label>
                        <button onClick={()=> display.btn1===0? setDisplay({...display, btn1:1}):setDisplay({...display, btn1:0})} className='btn btn-block btn-light'>{display.btn1===1?<i class="fas fa-sort-down"></i>:<i class="fas fa-sort-up"></i>}</button>
                    </div>
                    {display.btn1===1?
                    <p style={{fontSize:'18px', textAlign:'left', width:'100%', padding:'15px'}}>
                        Arabic language does not supported in this OP, what can i do now?
                    </p>: null
                }
                </div>
                <div className='data_box'>
                    <div className='title_bar'>
                        <label>Family Status</label>
                        <button onClick={()=> display.btn2===0? setDisplay({...display, btn2:1}):setDisplay({...display, btn2:0})} className='btn btn-block btn-light'>{display.btn2===1?<i class="fas fa-sort-down"></i>:<i class="fas fa-sort-up"></i>}</button>
                    </div>
                    {display.btn2===1?
                    <div className='sample_table_body'>
                    <div className='some_cell_in_table'>
                        <label>Number of Sisters</label>
                        <label style={{fontWeight:'bold'}}>2</label>
                    </div>
                    <div className='some_cell_in_table'>
                        <label>Number of Brothers</label>
                        <label style={{fontWeight:'bold'}}>1</label>
                    </div>
                    <div className='some_cell_in_table'>
                        <label>Is the mother alive</label>
                        <label style={{fontWeight:'bold'}}>yes</label>
                    </div>
                    <div className='some_cell_in_table'>
                        <label>Guardian name</label>
                        <label style={{fontWeight:'bold'}}>Ibtihaj Mustafa</label>
                    </div>
                    <div className='some_cell_in_table'>
                        <label>Guardian Relation</label>
                        <label style={{fontWeight:'bold'}}>Mother</label>
                    </div>
                    <div className='some_cell_in_table'>
                        <label>Family icome</label>
                        <label style={{fontWeight:'bold'}}>AED 600</label>
                    </div>
                </div>:
                null
                }
                    
                </div>
                <div className='data_box'>
                    <div className='title_bar'>
                        <label>Educational Status</label>
                        <button onClick={()=> display.btn3===0? setDisplay({...display, btn3:1}):setDisplay({...display, btn3:0})} className='btn btn-block btn-light'>{display.btn3===1?<i class="fas fa-sort-down"></i>:<i class="fas fa-sort-up"></i>}</button>
                    </div>
                    {display.btn3===1?
                    <div className='column_table_body'>
                    <div className='some_cell_in_table'>
                        <label>School Name</label>
                        <label style={{fontWeight:'bold'}}>New Amman Secondary School</label>
                    </div>
                    <div className='table_down_cells'>
                        <div className='some_cell_in_table'>
                            <label>Educational Level</label>
                            <label style={{fontWeight:'bold'}}>Elementry</label>
                        </div>
                        <div className='some_cell_in_table'>
                            <label>Study Status</label>
                            <label style={{fontWeight:'bold'}}>20</label>
                        </div>
                    </div>
                </div>: null
                } 
                </div>
                <div className='data_box'>
                    <div className='title_bar'>
                        <label>Supervisor</label>
                        <button onClick={()=> display.btn4===0? setDisplay({...display, btn4:1}):setDisplay({...display, btn4:0})} className='btn btn-block btn-light'>{display.btn4===1?<i class="fas fa-sort-down"></i>:<i class="fas fa-sort-up"></i>}</button>
                    </div>
                    {display.btn4===1?
                    <div className='sample_table_body'>
                    <div className='some_cell_in_table'>
                        <label>Some Title</label>
                        <label style={{fontWeight:'bold'}}>Some Data</label>
                    </div>
                    <div className='some_cell_in_table'>
                        <label>Some Title</label>
                        <label style={{fontWeight:'bold'}}>Some Data</label>
                    </div>
                    <div className='some_cell_in_table'>
                        <label>Some Title</label>
                        <label style={{fontWeight:'bold'}}>Some Data</label>
                    </div>
                </div>: null
                }   
                </div>
            </div>
        </div>
    )
}

export default OrphanReport
