import React from 'react'
import './becomeASponCom.css'

const BecomeASponCom = (props) => {
    return (
        <div className='becomeASpon_com'>
            <img src={props.image} alt={props.alt_text} className='spon_img'/>
            <label>{props.text}</label>
        </div>
    )
}
export default BecomeASponCom