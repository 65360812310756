import React from 'react'
import './humanitarian.css'
import HumanitarianCom from './humanitarianCom/humanitarianCom'
import Carousel from 'react-bootstrap/Carousel'
import { useSelector } from 'react-redux'


const Humanitarian = () => {
    const humanCases = useSelector(state=> state.humanCases);

    return (
        <section id='humanitarian'>
            <label style={{padding:'40px 40px 0px 40px',fontWeight:'bold', fontSize:'25px', width:'100%', textAlign:'left'}}>Humanitarian Cases</label>
            <Carousel id='human'>
                {humanCases?.CASES?.map(item=>
                    <Carousel.Item interval={2500} key={item.CID}>
                    <HumanitarianCom 
                        ID={item.CID}
                        humanImg={item.C_URL} 
                        title={item.C_TITLE}
                        text='On July 17, 2021, Annijke Wade was in Angel Fire, New Mexico doing what she loves the most in the worls: moun-tain biking-when her life changed forever' 
                        raised={item.TOTAL} 
                        goal={item.REQ_AMNT}
                    />
                </Carousel.Item>
                    )}
            </Carousel>
        </section>
        
    )
}
export default Humanitarian