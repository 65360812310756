import React from 'react'
import './UserSponsor.css'
import UserSponsorCom from './userSponsorCom/UserSponsorCom'
import poor1 from '../../assets/poor1.jpg'
import poor2 from '../../assets/poor2.jpg'
import poor3 from '../../assets/poor3.jpg'
import poor4 from '../../assets/poor4.jpg'


const UserSponsor = () => {
    return (
        <div id='user_sponsor_page'>
            <h1><span style={{textDecoration:'underline', textDecorationColor:'#cc0000'}}>Manage, View, a</span>nd Gift your Sponsors</h1>
            <div id='user_sponsors_display'>
                <UserSponsorCom 
                img={poor1}
                name='Dana Ahmed Ali'
                nationality='Tago'
                beneficairy='156465231021'
                birth='27/02/2014'
                Sdate='4/03/2014'
                />
                <UserSponsorCom 
                img={poor2}
                name='Abu Bakr Idrees Mohammed'
                nationality='Chad'
                beneficairy='156465254111'
                birth='6/16/2011'
                Sdate='4/12/2014'
                />
                <UserSponsorCom 
                img={poor3}
                name='Lana Ali'
                nationality='Togo'
                beneficairy='156465231021'
                birth='27/02/2014'
                Sdate='4/03/2014'
                />
                <UserSponsorCom 
                img={poor4}
                name='Noor Mohammed'
                nationality='Chad'
                beneficairy='156465254111'
                birth='6/16/2011'
                Sdate='4/12/2014'
                />
            </div>
        </div>
    )
}

export default UserSponsor
