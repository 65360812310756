import React from 'react'
import './homapage.css'
import Banner from './sections/banner/banner'
import BecomeASpon from './sections/becomeASpon/becomeASpon'
import Donation from './sections/donations/donation'
import Humanitarian from './sections/humanitarian/humanitarian'
import Projects from './sections/projects/projects'
import Sukuk from './sections/sukuk/sukuk'
import Favorites from './sections/favorites/Favorites'

const HomePage = () => {
    return (
        <div id='home'>
            <Banner/>
            <Donation/>
            <Humanitarian/>
            <Sukuk/>
            <BecomeASpon/>
            <Projects/>
            <Favorites/>
        </div>
    )
}
export default HomePage