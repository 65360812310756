import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import donateIcon from '../../../../assets/donateIconForBanner.png'
import './PopUpPayment.css'
import { useSelector } from 'react-redux'

 
const PopUpPayment = (props) => {
    const compaginweNeed = useSelector(state=> state.compaginsSlider).CAMP_DETAILS_ALL?.filter(item=> item.ACTV_ID===props.data?.compaginId);
    const priceForAllDonationsIn = compaginweNeed?.map(item=>parseInt(item.DON_AMNT));
    const sumOfPriceForAllDonationsIn = priceForAllDonationsIn?.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    const [sukukAmount, setSukukAmount] = useState(parseInt(props.amount));
    const [compaginamount, setcompaginAmount] = useState(sumOfPriceForAllDonationsIn);
    const handleClick = (op) => {
        if (op==='-') {
            if (sukukAmount>0) {
                setSukukAmount(sukukAmount-parseInt(props.amount));
            }
        } else {
            setSukukAmount(sukukAmount+parseInt(props.amount));
        }
    }
    const handleClickFromCompagin = (op) => {
        if (op==='-') {
            if (sukukAmount>0) {
                setSukukAmount(compaginamount-sumOfPriceForAllDonationsIn);
            }
        } else {
            setSukukAmount(compaginamount+sumOfPriceForAllDonationsIn);
        }
    }
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 6
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };
    return (
        <Modal
        {...props}
        size="lg"
        centered
        className='popUp'
      >
        <Modal.Body>
            {props.elements===1?
            <div className='donation_item'>
            <div style={{backgroundImage:`url(${props.img})`}} className='donation_img'>
            </div>
            <div className='donation_details' style={{padding:'7px'}}>
                <label style={{width:'100%', textAlign:'left', fontSize:'17px', lineHeight:'1cm', padding:'5px 0px 0px 5px'}}>{props.title}</label>
            </div>
        </div>:
        compaginweNeed?.map(item=> 
            <div className='donation_item' key={item.DONTYPE_ID}>
            <div style={{backgroundImage:`url(https://www.emiratesrc.ae/mobileDonate/coup/banner/${item.DONTYPE_ID}.jpg)`}} className='donation_img'>
            </div>
            <div className='donation_details' style={{padding:'7px'}}>
                <label style={{width:'100%', textAlign:'left', fontSize:'17px', lineHeight:'1cm', padding:'5px 0px 0px 5px'}}>{item.TITLE_E}</label>
            </div>
        </div>
            )
        }
        
        <Carousel responsive={responsive} arrows={true}>
            <button className='spin_popUp'>
                <span style={{fontSize:'15px', opacity:'0.7'}}>AED</span>
                <span style={{fontSize:'15px', fontWeight:'bold'}}>{parseInt(props.amount)}</span>
            </button>
            <button className='spin_popUp'>
                <span style={{fontSize:'15px', opacity:'0.7'}}>AED</span>
                <span style={{fontSize:'15px', fontWeight:'bold'}}>{parseInt(props.amount)*2}</span>
            </button>
            <button className='spin_popUp'>
                <span style={{fontSize:'15px', opacity:'0.7'}}>AED</span>
                <span style={{fontSize:'15px', fontWeight:'bold'}}>{parseInt(props.amount)*3}</span>
            </button>
            <button className='spin_popUp'>
                <span style={{fontSize:'15px', opacity:'0.7'}}>AED</span>
                <span style={{fontSize:'15px', fontWeight:'bold'}}>{parseInt(props.amount)*4}</span>
            </button>
            <button className='spin_popUp'>
                <span style={{fontSize:'15px', opacity:'0.7'}}>AED</span>
                <span style={{fontSize:'15px', fontWeight:'bold'}}>{parseInt(props.amount)*5}</span>
            </button>
            <button className='spin_popUp'>
                <span style={{fontSize:'10px', opacity:'0.7'}}>AED</span>
                <span style={{fontSize:'15px', fontWeight:'bold'}}>{parseInt(props.amount)*6}</span>
            </button>
        </Carousel>
        <div style={{justifyContent:'center', gap:'20px'}} className='sukuk_inc_Dec'>
            <button style={{backgroundColor:'white', color:'black'}} className='sukukdec btn btn-light' onClick={()=> handleClick('-')}>-</button>
            <label style={{padding:'5px 30px 5px 30px', borderRadius:'30px', backgroundColor:'white', fontSize:'15px', fontStyle:'italic', fontWeight:'bold'}}>AED <span style={{fontSize:'20px', fontWeight:'bold', fontStyle:'none'}}>{sukukAmount}</span></label>
            <button style={{backgroundColor:'white', color:'black'}} className='sukukinc btn btn-light' onClick={()=> handleClick('+')}>+</button>
        </div>
        <div className='popUp_btns'>
            <button className='btn btn-block btn-light'><i style={{fontSize:'30px'}} class="fab fa-apple-pay"></i></button>
            <button className='btn btn-block btn-outline-light popUP_outline'>
                <span style={{width:'100%'}}>Add to Bag</span>
                <img src={donateIcon} alt='donate_btn_icon_banner' className='donate_btn_icon_banner'/>
            </button>
        </div>
        </Modal.Body>
      </Modal>
    )
}

export default PopUpPayment
