import React, {useState} from 'react'
import './AllSukukCom.css'

const AllSukukCom = (props) => {
    const [sukukAmount, setSukukAmount] = useState(100);
    const handleClick = (op) => {
        if (op==='-') {
            if (sukukAmount>0) {
                setSukukAmount(sukukAmount-1);
            }
        } else {
            setSukukAmount(sukukAmount+1);
        }
    }
    return (
        <div id='all_sukuk_com'>
            <label style={{width:'100%', textAlign:'left', fontSize:'20px', fontWeight:'bold'}}>{props.title}</label>
            <div className='sukuk_item'>
                <img className='sukuk_item_img' src={props.sukukImg} alt='sukuk_img' style={{flex:'1'}}/>
                <div className='sukuk_item_board_all'>
                    <div className='btn-description'>
                        <label>Donation Frequency</label>
                        <div className='sukuk_item_board_time'>
                        <button className='btn btn-block btn-light btn-sm px30_btn'>Daily</button>
                        <button className='btn btn-block btn-light btn-sm px30_btn'>Monthly</button>
                        <button className='btn btn-block btn-light btn-sm px30_btn'>Yearly</button>
                    </div>
                    </div>
                    <div className='btn-description'>
                        <label>Donation Amount</label>
                        <div className='sukuk_inc_Dec'>
                            <button className='sukukdec btn btn-light' onClick={()=> handleClick('-')}>-</button>
                            <label style={{fontSize:'15px', fontStyle:'italic', fontWeight:'bold'}}>AED <span style={{fontSize:'20px', fontWeight:'bold', fontStyle:'none'}}>{sukukAmount}</span></label>
                            <button className='sukukinc btn btn-light' onClick={()=> handleClick('+')}>+</button>
                        </div> 
                    </div>
                    <div className='sukuk_item_board_payment'>
                        <button className='btn btn-block btn-light px50_btn'><i class="fas fa-hand-holding-medical"></i></button>
                        <button className='btn btn-block btn-light px50_btn'><i class="fab fa-apple-pay"></i></button>
                        <button className='btn btn-block btn-light px50_btn'><i class="fas fa-sms"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllSukukCom
