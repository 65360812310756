import React, {useState} from 'react'
import './DonationSupportPage.css'
import supportKid from '../../assets/pexels-kindel-media-7979606.jpg'

const DonationSupportPage = () => {
    const [scrollValue, setScrollValue] = useState(150);
    const handleChange = (e) => {
        setScrollValue(e.target.value)
    }
    return (
        <div id='donation_support_page'>
            <h1 style={{textDecoration:'underline', textDecorationColor:'#cc0000'}}>Support Kids</h1>
            <div className='donation_support_page_display'>
                <div className='donation_support_page_left'>
                    <img src={supportKid} alt='support' className='support'/>
                </div>
                <div className='donation_support_page_right'>
                    <div className='donation_support_page_section'>
                        <h5 style={{fontWeight:'bold'}}>Donation Frequency</h5>
                        <div className='donation_frequ_btns'>
                            <button className='btn btn-block btn-light btn-frequ'>One Time</button>
                            <button className='btn btn-block btn-light btn-frequ'>Monthly</button>
                            <button className='btn btn-block btn-light btn-frequ'>Yearly</button>
                        </div>
                    </div>
                    <div className='donation_support_page_section'>
                        <h5 style={{fontWeight:'bold'}}>Donation Amount</h5>
                        <div className='donation_amount_btns'>
                            <input type="range" min="1" max="1000" value={scrollValue} onChange={handleChange} className="slider" id="myRange"/>
                            <div className='donation_amount_input'>
                                <label>AED</label><input type='text' value={scrollValue} onChange={handleChange}></input>
                            </div>
                        </div>
                    </div>
                    <div className='human_donation_btns'>
                        <div className='human_donation_btns_above'>
                            <button className='btn btn-block btn-light human_donate_ways btn1'><i style={{fontSize:'50px'}} class="fas fa-hand-holding-medical"></i></button>
                            <button className='btn btn-block btn-light human_donate_ways btn2'><i style={{fontSize:'50px'}} class="fas fa-sms"></i></button>
                            <button className='btn btn-block btn-light human_donate_ways btn3'><i style={{fontSize:'50px'}} class="fas fa-hand-holding-usd"></i></button>
                        </div>
                        <button className='btn btn-block btn-dark apple_human_btn'><i style={{fontSize:'40px'}} class="fab fa-apple-pay"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DonationSupportPage
