import React, {useEffect} from 'react'
import './footer.css'
import ERC from '../../../assets/logo.svg'
import { useLocation, useHistory } from 'react-router-dom'


const Footer = () => {
    const location = useLocation();
    useEffect(() => {
       if (location.pathname==='/login') {
            document.getElementById('footer').style.display='none'
        } else {
            document.getElementById('footer').style.display='flex'
        }
    }, [location])
    return (
        <footer id='footer'>
            <div id='footer_up'>
                <img src={ERC} alt='ERC_logo' className='ERC_logo_footer'/>
            </div>
            <div id='real_footer'>
                <ul id='about_US'>
                    <li><label style={{textDecoration:'underline', fontSize:'20px', color: '#cc0000', fontWeight:'bold'}}>About Us..</label></li>
                    <li><a href='/'>Establishment and international recognition</a></li>
                    <li><a href='/'>Our course</a></li>
                    <li><a href='/'>Our objectives</a></li>
                    <li><a href='/'>Our Role</a></li>
                    <li><a href='/'>Fundamental Principles</a></li>
                    <li><a href='/'>Our values</a></li>
                </ul>
                <ul id='about_US'>
                    <li><label style={{textDecoration:'underline', fontSize:'20px', color: '#cc0000', fontWeight:'bold'}}>Services..</label></li>
                    <li><a href='/'>Volunteer now</a></li>
                    <li><a href='/'>Sponsor an orphan</a></li>
                    <li><a href='/'>Sponsors portal</a></li>
                    <li><a href='/'>Local aid portal</a></li>
                    <li><a href='/'>Reqruiment</a></li>
                    <li><a href='/'>Donate now</a></li>
                </ul>
                <div id='contact_us'>
                    <div style={{display:'flex', flexDirection:'column', width:'100%'}} className='ques'>
                        <label style={{fontWeight:'bold', fontSize:'25px', width:'100%'}}>Need Help?</label>
                        <label style={{fontSize:'17px'}}>Contact Us Now</label>
                    </div>
                    <form>
                        <div id='first_inputs'>
                        <div className='form_inputs_inline'>
                            <i class="fas fa-user"></i>
                            <div className='input'>
                                <label for='name'>Name</label>
                                <input type='text' id='name'></input>
                            </div> 
                        </div>
                        <div className='form_inputs_inline'>
                            <i class="far fa-envelope"></i>
                            <div className='input'>
                                <label for='email'>E-mail</label>
                                <input type='text' id='email'></input>
                            </div> 
                        </div>
                        </div>
                        <div className='messageInput'>
                            <label for='message'>Message</label>
                            <textarea type='text' id='message'></textarea>
                        </div> 
                        <button className='btn btn-block btn-danger contactBtn'>Send</button>
                    </form>
                </div>
            </div>
            <div id='footer_down'>
                <label style={{color:'white', width:'100%', textAlign:'center'}}>@ ALL RIGHT RESERVED EMIRATES RED CRESCENT 2021</label>
                <div className='social_media'>
                    <i class="fab fa-facebook"></i>
                    <i class="fab fa-instagram"></i>
                    <i class="fab fa-twitter"></i>
                    <i class="fab fa-snapchat"></i>
                </div>
            </div>
        </footer>
    )
}
export default Footer

