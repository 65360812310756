import React, {useState} from 'react'
import './donationCom.css'
import donateIcon from '../../../../../assets/donateicon.png'
import PopUpPayment from '../../popUpPayment/PopUpPayment'
const DonationCom = (props) => {
    const [donationAmount, setDonationAmount] = useState(parseInt(props.amount));
    const [popUpModal, setPopUpModal] = useState(false);

    const [liked, setLiked] = useState(true)
    return (
        <div className='donation_com'>
            <button className='donate_btn_wrap'><img src={donateIcon} alt='donate_btn_icon' className='icon_for_donation'/></button>
            <div className='donation_item'>
                <div style={{backgroundImage:`url(${props.DonImg})`}} className='donation_img'>
                    <button onClick={()=> liked? setLiked(false): setLiked(true)} className='like_btn'>{liked? <i style={{color:'#ee4236'}} class="fas fa-heart"></i>:<i style={{color:'white'}} class="fas fa-heart"></i>}</button>
                </div>
                <div className='donation_details'>
                    <label style={{width:'100%', textAlign:'left', fontSize:'17px', lineHeight:'1cm', padding:'5px 0px 0px 5px'}}>{props.title}</label>
                    <div className='donation_inc_Dec'>
                        <button onClick={()=>setPopUpModal(true)} className='single_donate_btn'>Donate now</button>
                        <label style={{fontSize:'15px', fontStyle:'italic', paddingRight:'10px'}}>AED <span style={{fontSize:'15px', fontWeight:'bold', fontStyle:'none'}}>{donationAmount}</span></label>
                    </div>
                </div>
            </div>
            <PopUpPayment
                show={popUpModal}
                amount={props.amount}
                img={props.DonImg}
                elements={1}
                title={props.title}
                onHide={() => setPopUpModal(false)}
            />
        </div>
    )
}
export default DonationCom

