import React, {useEffect} from 'react'
import './header.css'
import ENG from '../../../assets/united-states-of-america.svg'
import UAE from '../../../assets/united-arab-emirates.svg'
import ERC from '../../../assets/logo.png'
import ERCMob from '../../../assets/logo.svg'
import { useLocation, useHistory } from 'react-router-dom'



const Header = () =>{
    const location = useLocation();
    const history = useHistory();
    const openFromNav = (str) => {
        switch (str) {
            case 'home':
                history.push(`/`)
                break;
            case 'donations':
                history.push(`/donations`)
                break;
            case 'projects':
                history.push(`/projects`)
                break;
            case 'logIn':
                history.push(`/login`)
                break;
            default:
                history.push(`/`)
                break;
        }
    }
    useEffect(() => {
        if (location.pathname==='/') {
            document.getElementById('home-nav').style.color='#ee4236';
            document.getElementById('donations-nav').style.color='#818181';
            document.getElementById('projects-nav').style.color='#818181';
            document.getElementById('header').style.display='flex'
        } else if (location.pathname==='/donations') {
            document.getElementById('donations-nav').style.color='#ee4236';
            document.getElementById('home-nav').style.color='#818181';
            document.getElementById('projects-nav').style.color='#818181';
            document.getElementById('header').style.display='flex'
        } else if (location.pathname==='/projects') {
            document.getElementById('projects-nav').style.color='#ee4236';
            document.getElementById('home-nav').style.color='#818181';
            document.getElementById('donations-nav').style.color='#818181';
            document.getElementById('header').style.display='flex'
        } else if (location.pathname==='/login') {
            document.getElementById('header').style.display='none'
        }
    }, [location])
    return (
        <header id='header'>
            <div id='select_lang_area'>
             <div class="dropdown">
              <button class=" dropDown dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img src={ENG} alt='Eng_Logo' className='lang_logo inDrop'/>
              </button>
              <div class="dropdown-menu" aria-labelledby="Lang">
                <a class="dropdown-item" href="/">
                    <img src={ENG} alt='Eng_Logo' className='lang_logo'/>
                    <label>USA</label>
                </a>
                <a class="dropdown-item" href="/">
                    <img src={UAE} alt='Eng_Logo' className='lang_logo'/>
                    <label>UAE</label>
                </a>
              </div>
             </div>
             <div className='header_btns'>
                 <button onClick={()=> openFromNav('mybag')} className='btn btn-block btn-light header_icons'><i class="fas fa-shopping-bag"></i></button>
                 <button onClick={()=> openFromNav('logIn')} className='btn btn-block btn-light header_icons'><i class="fas fa-user"></i></button>
                 <button type="button" class="btn btn-block btn-light header_icons position-relative">
                    <i class="fas fa-bell"></i>
                    <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                        1
                    </span>
                 </button>
            </div>
            </div>
            <div id='down_header'>
                <img src={ERC} alt='ERC_logo' className='ERC_logo'/>
                <img src={ERCMob} alt='ERC_logo' className='ERC_logo_mob'/>
                <ul>
                    <li>
                        <button onClick={() => openFromNav('home')} className='btnForNav' id='home-nav'>Home</button>
                    </li>
                    <li>
                        <button onClick={() => openFromNav('donations')} className='btnForNav' id='donations-nav'>Donations</button>
                    </li>
                    <li>
                        <button onClick={() => openFromNav('projects')} className='btnForNav' id='projects-nav'>Projects</button>
                    </li>
                    <li>
                        <button onClick={() => openFromNav('contact_us')} className='btnForNav' href='/'>Contact Us</button>
                    </li>
                    <li>
                        <button onClick={() => openFromNav('about_us')} className='btnForNav' href='/'>About Us</button>
                    </li>
                </ul>
            </div>
        </header>
    )
}
export default Header