import React from "react";
import './projects.css'
import projectBC from '../../../../assets/projectHeader.png'
import ProjectsCom from "./projectsCom/projectsCom";
import Carousel from 'react-bootstrap/Carousel'
import { useSelector } from 'react-redux'


const Projects = () => {
    const projects = useSelector(state=> state.projects);
    const last16 = projects.PRJ_ACTIVE?.slice(projects.PRJ_ACTIVE.length-16);
    return (
        <section id='projects_sec'>
            <label style={{fontWeight:'bold', fontSize:'25px', width:'100%', textAlign:'left'}}>Projects</label>
            <div className='project_header' style={{backgroundImage:`url(${projectBC})`}}>
                <label style={{fontSize:'20px', color:'white', fontWeight:'bold'}}>Together</label>
                <label style={{fontSize:'20px', color:'white', fontWeight:'bold'}}>Help Build The World!</label>
                <button className='project_header_btn'>View All Projects <span style={{textAlign:'left'}}><i class="fas fa-long-arrow-alt-right"></i></span></button>
            </div>
            <Carousel id='projects'>
                <Carousel.Item interval={2500}>
                <div className='projects_display'>
                    {last16?.slice(8).map(item => 
                        <ProjectsCom img={`https://emiratesrc.ae/mobiledonate2/images/prj/${item.PRJTYPE_ID}.png`} title={item.PRJTYPE_NAME_E} project_img_alt={item.PRJTYPE_NAME_E}/>
                        )}
                </div>   
                </Carousel.Item>
                <Carousel.Item interval={2500}>
                <div className='projects_display'>
                {last16?.slice(0,8).map(item => 
                        <ProjectsCom img={`https://emiratesrc.ae/mobiledonate2/images/prj/${item.PRJTYPE_ID}.png`} title={item.PRJTYPE_NAME_E} project_img_alt={item.PRJTYPE_NAME_E}/>
                        )}
                </div>
                </Carousel.Item>
            </Carousel>
            
        </section>
    )
}
export default Projects