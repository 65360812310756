import React from 'react'
import './ProjectsDisplay.css'
import ProjectsCom from '../../../homePage/sections/projects/projectsCom/projectsCom'
import ph1 from '../../../../assets/pexels-hassan-ahmad-2259636.jpg'
import ph2 from '../../../../assets/ambulance.jpg'
import ph3 from '../../../../assets/buildingcon.jpg'

const ProjectsDisplay = () => {
    return (
        <div id='projects_page_display'>
            <label style={{fontWeight:'bold', fontSize:'25px', width:'100%', textAlign:'left', textDecoration:'underline', textDecorationColor:'#cc0000'}}>Projects</label>
            <div className='projects_display'>
                <ProjectsCom img={ph1} title='Mousque'/>
                <ProjectsCom img={ph2} title='Ambulance'/>
                <ProjectsCom img={ph3} title='Building Construction'/>
                <ProjectsCom img={ph1} title='Mousque'/>
                <ProjectsCom img={ph1} title='Mousque'/>
                <ProjectsCom img={ph1} title='Mousque'/>
                <ProjectsCom img={ph2} title='Ambulance'/>
                <ProjectsCom img={ph3} title='Building Construction'/>
                </div>  
        </div>
    )
}

export default ProjectsDisplay
