import React, {useState} from 'react'
import './BagItemCom.css'

const BagItemCom = (props) => {
    const [donationAmount, setDonationAmount] = useState(props.amount);
    const handleClick = (op) => {
        if (op==='-') {
            if (donationAmount>0) {
            setDonationAmount(donationAmount-1);
            }
        } else {
            setDonationAmount(donationAmount+1);
        }
    }
    return (
        <div className='bag_item_com'>
            <img src={props.itemImg} alt='item_img' className='bag_item_img'/>
            <label style={{width:'100%', flex:'1'}}>{props.title}</label>
            <div className='donation_inc_Dec_bag'>
                <button className='dec' onClick={()=> handleClick('-')}>-</button>
                <label style={{fontSize:'15px', fontStyle:'italic', fontWeight:'bold'}}>AED <span style={{fontSize:'20px', fontWeight:'bold', fontStyle:'none'}}>{donationAmount}</span></label>
                <button className='inc' onClick={()=> handleClick('+')}>+</button>
            </div>
        </div>
    )
}

export default BagItemCom
